import type { ReactNode } from 'react';
import React from 'react';
import { useInView } from 'react-intersection-observer';

import { STANDARD_INTERSECTION_OBSERVER_MARGIN } from 'constants/appConstants';

const defaultOptions = {
  triggerOnce: true,
  rootMargin: STANDARD_INTERSECTION_OBSERVER_MARGIN
};

export interface IIntersectionObserverProps {
  forceLoad?: boolean;
  children?: ReactNode;
  placeholder?: ReactNode;
  makePlaceholder?: (ref?: (node?: Element | null) => void) => React.ReactNode;
  options?: any;
}

// TODO figure out way to mock react-intersection-observer in unit tests
const IntersectionObserver = ({ forceLoad, children, makePlaceholder, placeholder, options }: IIntersectionObserverProps) => {
  const [ref, inView] = useInView({ ...defaultOptions, ...options });
  if (inView || forceLoad) {
    return <>{children}</>;
  }
  if (makePlaceholder) {
    return makePlaceholder(ref);
  }
  return <div ref={ref}>{placeholder}</div>;
};

export default IntersectionObserver;
