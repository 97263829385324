import PropTypes from 'prop-types';

import LandingPageImage from 'components/landing/LandingPageImage';
import MelodyVideoPlayer from 'components/common/melodyVideo/MelodyVideoPlayer';

const LandingPageMedia = ({
  type,
  sources,
  src,
  srcset,
  alt,
  poster,
  autoplay,
  loop,
  embeddedRatio,
  shouldLazyLoad,
  isEmbedded,
  tracks,
  isFullWidth,
  slotIndex,
  ...otherProps
}) => {
  // the first couple of slots are probably above the fold -
  //  - don't lazy load the images
  //  - make the load high priority
  const probablyAboveTheFold = slotIndex < 2;
  if (type === 'image') {
    return (
      <LandingPageImage
        {...otherProps}
        sources={sources}
        src={src}
        srcset={srcset}
        alt={alt}
        shouldLazyLoad={!probablyAboveTheFold && shouldLazyLoad}
        isFullWidth={isFullWidth}
        fetchPriority={probablyAboveTheFold ? 'high' : undefined}
      />
    );
  } else if (type === 'video') {
    const slotDetails = {
      src,
      poster,
      isEmbedded,
      autoplay,
      loop,
      embeddedRatio,
      tracks
    };
    return <MelodyVideoPlayer {...otherProps} slotDetails={slotDetails} shouldLazyLoad={!probablyAboveTheFold && shouldLazyLoad} />;
  } else {
    return null;
  }
};

LandingPageMedia.propTypes = {
  type: PropTypes.oneOf(['image', 'video']),
  sources: PropTypes.array,
  src: PropTypes.string,
  alt: PropTypes.string,
  poster: PropTypes.string,
  autoplay: PropTypes.bool,
  loop: PropTypes.bool,
  embeddedRatio: PropTypes.string,
  shouldLazyLoad: PropTypes.bool,
  isEmbedded: PropTypes.bool,
  tracks: PropTypes.array,
  isFullWidth: PropTypes.bool
};

export default LandingPageMedia;
